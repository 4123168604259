@import 'src/styles/variables';

.selections {
  display: flex;
  width: 100%;
  margin: 15px auto;
}

.chart {
  @include card-style;
  flex: 2;
  padding: 30px;
  text-align: center;
  height: 500px;
  min-width: 300px;
  position: sticky;
  align-self: flex-start;
  top: 0;
}

.sliders {
  @include card-style;
  flex: 3;
  min-width: 400px;
  text-align: left;
  margin-left: 20px;
  padding: 10px;
}

.sliderTitle {
  margin-left: 45px;
  padding: 15px 0;
  font-weight: 600;
}


@media only screen and (max-width: 600px) {
  .selections {
    flex-wrap: wrap;

    .chart {
      position: relative;
    }
  }

  .sliders {
    margin-left: 0;
    padding: 0;
    min-width: none;
  }

  .chart {
    position: relative;
  }
}