@import 'src/styles/variables';

.offerLetters {
  position: relative;
  margin-bottom: 100px;

  .offerLetterTopSection {
    display: flex;

    .offerFilters {
      flex: 1;
    }

    .offerStats {
      flex: 2;
      display: flex;
    }

    .offerStatsTitle {
      font-weight: 600;
    }

    .offerStatsRow {
      display: flex;

      .offerStat {
        width: 100px;
      }
    }

    .offerStatsLeft {
      flex: 1;
      margin-left: 15px;
    }

    .offerStatsRight {
      flex: 0 0 300px;
      height: 250px;
      margin-top: 25px;
    }
  }
  
  .offerActions {
    position: absolute;
    top: 0;
    right: 0;
  
    a, button {
      margin-right: 10px;
  
      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  .offerStatusFilters {
    @include card-style;
  
    padding: 20px;
    width: 300px;
  }
  
  .offerNameFilter {
    @include card-style;
  
    width: 300px;
    margin: 15px 0;
    padding: 15px 20px;
  }
  
  .offerTable {
    max-height: 1500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .tableIcon {
    cursor: pointer;
    color: $color-primary;
    font-size: 20px;
    text-align: center;
    vertical-align: middle;

    &.disabled {
      color: $color-disabled;
    }
  }
  
  .navigateIcon {
    // minor hack to force alignment, not sure
    margin-top: -5px;
  }

  .copyOfferLink {
    text-align: center;
  }
}
