@import 'src/styles/variables';

.login {
  @include card-style;
  padding: 20px;
  width: 600px;
  margin: 0 auto;

  .submit {
    margin-top: 20px;
  }

  .signUpLink {
    font-size: 13px;
    margin-top: 5px;
    font-style: italic;
  }
}