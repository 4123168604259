@import 'src/styles/variables';

.defaultBundleBuilderPage {
  margin-top: 30px;

  .defaultBundleBuilder {
    margin-top: 25px;
  }
  
  .roleBox {
    @include card-style;
    width: 500px;
    padding: 15px 30px 25px 30px;
    position: relative;
  }
  
  .addRoleButton {
    position: absolute;
    right: 25px;
    top: 10px;
  }
  
  .pointSpinner {
    margin-top: 15px;
    width: 50%;
  }
  
  .noRole {
    text-align: center;
    margin-top: 100px;
  }
  
  .spinnerSubtext {
    font-size: 12px;
    font-style: italic;
  }

  .salarySpinner {
    width: 50%;
    margin-top: 15px;
  }
}
