@import 'src/styles/variables';

.createBundle {
  .employeeCard {
    @include card-style;
    padding: 20px;
    width: 500px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .submitSuccess {
    @include card-style;
    padding: 20px;
  }
}

.createVerifyModal {
  .verifyCheckbox {
    margin-top: 10px;
  }
}

.previewModalText {
  padding: 30px;
  font-size: 18px;
  max-width: 600px;
  text-align: center;
}
