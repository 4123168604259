@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body{margin:0;font-family:'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:#f8f6f6}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

.notifications{position:fixed;right:0;bottom:0;margin-right:20px;margin-bottom:20px;z-index:1501}.notifications div{padding:15px 30px;color:#fff;box-shadow:2px 2px 2px #e2d9d9;opacity:1;animation-name:fadeInOutOpacity;animation-iteration-count:1;animation-timing-function:ease-in-out;animation-duration:8s}@keyframes fadeInOutOpacity{0%{opacity:0}20%{opacity:1}80%{opacity:1}100%{opacity:0}}.notifications .success{background:#67e85d}.notifications .error{background:#f15b5b}.notifications .warning{background:#ffe772}

html{height:100%}body{min-height:100%;position:relative}.header{background:#062677;height:60px;display:flex;align-items:center;justify-content:space-between;padding:5px 10px}.header img{height:50px}.content{position:relative;padding:30px}.content.navPresent{margin-left:250px}.navBar{display:flex;flex-direction:column;justify-content:space-between;background:#fff;width:250px;position:absolute;top:70px;left:0;height:calc(100% - 70px)}.navBarFooter{font-size:14px;color:#666;text-align:center;padding:30px 10px}h1,h2,h3,h4,h5{margin-top:0}@media only screen and (max-width: 600px){.content{padding:5px}}

.rewardCallout{padding:20px 24px;width:500px;z-index:1501}.rewardCallout .rewardCalloutTitle{margin-bottom:5px}.rewardCallout .rewardCalloutTitle:not(:first-of-type){margin-top:15px}.rewardInfoIcon{color:#062677;font-size:22px;-webkit-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer}

.reward{display:flex;border-top:1px solid #f8f6f6;padding:20px 15px;align-items:center}.reward:first-child{border-top:none}.reward .lock{width:20px;height:20px;-webkit-user-select:none;-ms-user-select:none;user-select:none}.infoNameWrapper{display:flex;height:22px;align-items:center;flex:0 0 210px;margin-right:10px}.infoWrapper{cursor:default;margin-right:15px;width:22px;height:22px}.name{margin-right:15px;flex:0 0 200px}.lock{margin-right:3px;z-index:1}.lock .lockIcon{cursor:pointer;color:#062677;font-size:20px}.lock.disabled .lockIcon{color:#bdbdbd}.sliderWrapper{display:flex;flex:2 1}.slider{flex:1 1;height:28px}.defaultMarker{position:relative;width:1px;height:30px;z-index:-1}.defaultMarker:after{content:"";position:absolute;z-index:-1;top:-80%;bottom:0;left:50%;height:100%;height:inherit;border-left:1px dashed #b5a0a0}.rewardTooltip{font-size:12px !important;background-color:#062677 !important}.rewardTooltip.rewardDisabled{background-color:#bdbdbd !important}.rewardTooltipArrow{color:#062677 !important}.rewardTooltipArrow.rewardDisabled{color:#bdbdbd !important}

.selections{display:flex;width:100%;margin:15px auto}.chart{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;flex:2 1;padding:30px;text-align:center;height:500px;min-width:300px;position:-webkit-sticky;position:sticky;align-self:flex-start;top:0}.sliders{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;flex:3 1;min-width:400px;text-align:left;margin-left:20px;padding:10px}.sliderTitle{margin-left:45px;padding:15px 0;font-weight:600}@media only screen and (max-width: 600px){.selections{flex-wrap:wrap}.selections .chart{position:relative}.sliders{margin-left:0;padding:0;min-width:none}.chart{position:relative}}

.bundleBuilder{padding:30px;position:relative;text-align:center}.bundleBuilder .totalCompensation{min-height:48px}.bundleBuilder .fourDay{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:400px;margin:0 auto}.bundleBuilder .fourDay .fourDayTitle{font-weight:bold}.bundleBuilder .fourDay .fourDayToggle{margin-top:10px;margin-left:150px}.bundleBuilder .disableMessage{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px 30px;display:inline-block;margin-top:10px;margin-bottom:10px}.bundleBuilder .notes{max-width:600px;margin:0 auto 15px auto}.tourIntro{width:500px;padding:40px}.tourIntro .tourIntroSubmit{display:block;margin:0 auto}.pointsRemaining{font-size:32px;font-weight:500;height:48px;max-width:600px;margin:0 auto}.resetButton{margin-left:15px}@media only screen and (max-width: 600px){.pointsRemaining{height:auto}.bundleBuilder{padding:0}.bundleBuilder .fourDay{padding:5px;width:100%}}

.defaultBundleBuilderPage{margin-top:30px}.defaultBundleBuilderPage .defaultBundleBuilder{margin-top:25px}.defaultBundleBuilderPage .roleBox{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;width:500px;padding:15px 30px 25px 30px;position:relative}.defaultBundleBuilderPage .addRoleButton{position:absolute;right:25px;top:10px}.defaultBundleBuilderPage .pointSpinner{margin-top:15px;width:50%}.defaultBundleBuilderPage .noRole{text-align:center;margin-top:100px}.defaultBundleBuilderPage .spinnerSubtext{font-size:12px;font-style:italic}.defaultBundleBuilderPage .salarySpinner{width:50%;margin-top:15px}

.backButton{position:absolute;top:15px;left:15px}

.createBundle .employeeCard{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:500px;margin-top:30px;margin-bottom:30px}.createBundle .submitSuccess{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px}.createVerifyModal .verifyCheckbox{margin-top:10px}.previewModalText{padding:30px;font-size:18px;max-width:600px;text-align:center}

.rewardTile{position:relative;width:150px;text-align:center;background:#fff;padding:20px;margin:15px;border-radius:5px;box-shadow:0px 3px 5px rgba(0,0,0,0.1);display:flex;flex-direction:column;justify-content:center;cursor:pointer;transition:0.5s;-webkit-user-select:none;-ms-user-select:none;user-select:none}.rewardTile.active{background:rgba(34,94,244,0.2)}.rewardTileLabel{font-weight:500}.delete{position:absolute;top:15px;right:15px;fill:#666}

.feedback .employeeInfo{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;width:500px;margin:0 auto;padding:15px}.feedback .employeeInfo button{margin-top:15px}.feedback .questions{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:15px}.feedback .questions .rewards{display:flex}.feedback .questions .rewards .feedbackRewardTile{flex:1 1;background:#f8f6f6}.feedback .questions .rewards .feedbackRewardTile.active{background:rgba(34,94,244,0.2)}.feedback .success{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:15px;width:600px;margin:0 auto}.feedback .success .successMessage{text-align:center;font-weight:bold}.feedback .feedbackSubtext{font-size:14px;font-style:italic}


.employeeModal .salarySpinner{width:50%}.employeeModal .addEmployeeRoleButton{text-align:right}


.roleModal .pointSpinner{margin-top:15px;width:50%}.roleModal .fourDay{margin-top:15px;display:flex;align-items:center;justify-content:center}.roleModal .fourDay div{flex:1 1}.roleModal .fourDay div:first-of-type{margin-right:5px}.roleModal .fourDay div:last-of-type{margin-left:5px}

.employeeList{position:relative;margin-bottom:100px}.employeeActions{position:absolute;top:0;right:0}.employeeActions button{margin-right:10px}.employeeActions button:last-child{margin-right:0}.employeeStatusFilters{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:300px}.employeeNameFilter{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;width:300px;margin:15px 0;padding:15px 20px}.employeeTable{max-height:1000px;overflow-y:auto;overflow-x:hidden}.tableIcon{cursor:pointer;color:#062677;font-size:20px;text-align:center;vertical-align:middle}.navigateIcon{margin-top:-5px}

.employeeCard{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;position:relative;padding:20px;width:500px;margin-top:30px;margin-bottom:15px}.employeeCard .emailWarning{display:inline-block;vertical-align:middle}.employeeCard .emailWarning i{color:#d41212}.employeeCard .emailWarning i,.employeeCard .emailWarning span{vertical-align:middle}.employeeCard .emailWarning span{font-style:italic}.employeeCard .cardLabel{font-weight:500;vertical-align:middle}.employeeCard .employeeActions{position:absolute;right:10px;top:10px}.employeeBundle{margin-top:30px}.employeeBundle .totalCompensation{font-size:18px;font-weight:600}.employeeBundle .fourDayEnabled{font-size:16px;font-weight:600}

.editPencil{color:#062677}

.editPencil{color:#062677}


.roleList{position:relative;margin-bottom:100px}.roleList .roleStatusFilters{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:300px}.roleList .roleActions{position:absolute;top:0;right:0}.roleList .roleActions button{margin-right:10px}.roleList .roleActions button:last-child{margin-right:0}.roleList .roleTable{max-height:1500px;overflow-y:auto;overflow-x:hidden}.roleList .roleFilterGroup{display:flex}.roleList .roleFilter{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;width:300px;margin:15px;padding:15px 20px}.roleList .roleFilter:first-of-type{margin-left:0}.roleList .roleFilter:last-of-type{margin-right:0}.roleList .departmentFilter{display:flex}.roleList .departmentFilter .departmentDropdown{flex:1 1}.roleList .departmentFilter .departmentClear{flex:0 0 25px;align-self:flex-end}.roleList .tableIcon{display:block;width:40px;cursor:pointer;color:#062677;font-size:15px;text-align:center;vertical-align:middle;margin:0 auto}.roleList .navigateIcon{margin:0 auto;margin-top:-5px;font-size:20px}

.statCard{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;margin:20px;padding:20px;width:200px;text-align:center;display:flex;flex-direction:column;justify-content:space-between;flex:1 1}.statCard:first-child{margin-left:0}.statCard:last-child{margin-right:0}.statCard .statTitle{flex:1 1;font-size:16px;font-weight:500;white-space:nowrap}.statCard .stat{flex:3 1}

.inviteTeam .inviteLink{position:relative;width:500px;margin:0 auto}.inviteTeam .inviteCopy{cursor:pointer;display:inline-block;-webkit-font-smoothing:antialiased;font-style:normal;font-weight:normal;position:absolute;bottom:6px;right:8px;top:auto;font-size:16px;line-height:18px}.inviteTeam .inviteCopied{font-style:italic;font-weight:600;position:absolute;top:0;right:0;opacity:0;transition:opacity 1s ease}.inviteTeam .inviteCopied.fadeIn{opacity:1}.inviteTeam .statCards{display:flex;margin:50px 0}.inviteTeam .question{margin-top:20px}.inviteTeam .rewards{display:flex}

.requestDemo{display:block;margin:0 auto}.features{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:10px}.features div{font-weight:500;display:flex;align-items:center;margin-bottom:5px}.features div i{margin-right:5px;color:#67e85d}.planComparison{margin-top:30px;display:flex}.planComparison .plan{flex:1 1}.planComparison .plan:first-of-type{padding-right:15px}.planComparison .plan:last-of-type{padding-left:15px}

.editReward{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;position:relative;padding:20px 15px;margin-bottom:20px}.editReward .rewardConfig{display:flex;align-items:center;padding-top:15px;padding-bottom:5px}.editReward .rewardActions{position:absolute;right:10px;top:15px}.editReward .deleteButton{color:#f15b5b}.editReward .rewardLabel{font-size:18px;font-weight:600}.editReward .rewardDropdown{flex:0 1 100px;margin-right:20px}.editReward .rewardCheckboxes{flex:1 1;align-self:self-end}.editReward .rewardCheckbox{margin-bottom:5px}.editReward .rewardNumber{width:125px;margin-right:10px}.editReward .rewardModifyLabel{display:flex;justify-content:center;align-content:center}.editReward .rewardInfoIcon{margin-left:5px}.editReward .rewardSubText{font-size:15px;font-style:italic}.editReward .rewardEdit{font-size:15px;margin-left:10px;cursor:pointer}.editReward .rewardEditText{width:300px;display:inline-block}.modifyTooltipCopy{font-size:13px;padding:5px}

.rewards{position:relative}.rewards .submit{margin:0 auto;display:block;font-size:18px;height:40px}.rewards .rewardItems{display:flex;flex-direction:column}.rewards .rewardAddButton{position:absolute;right:0;margin:10px}

.adminDashboard{margin:-30px}.adminDashboard .adminDashboardContent{padding:30px}.statCards{display:flex;margin:50px 0}.statTable{margin-top:15px}.statCard,.statTable{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9}.statSort{display:flex;width:50%}.statSort .statSortSelect{flex:1 1}.statSort .statSortOption{flex:3 1;margin:0 15px}.statSort .statSortOption:first-of-type{margin-left:0}.statSort .statSortOption:last-of-type{margin-right:0}.statCard{margin:20px;padding:20px;width:200px;text-align:center;display:flex;flex-direction:column;justify-content:space-between;flex:1 1}.statCard:first-child{margin-left:0}.statCard:last-child{margin-right:0}.statCard .statTitle{flex:1 1;font-size:16px;font-weight:500;white-space:nowrap}.statCard .stat{flex:3 1}.statTable{background:#fff;padding:20px;width:auto}.statTable .statTitle{margin-bottom:10px;font-size:24px;font-weight:500}

.verifyCompany{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;margin:0 auto;width:500px}.verifyCompany .submitButton{margin-top:15px}

.selectRewards .submit{margin:0 auto;display:block;font-size:18px;height:40px}.selectRewards .rewards{display:flex;flex-wrap:wrap}.selectRewards .addCustom{background:#fff;width:150px;padding:20px;margin:15px;border:1px dashed #062677;border-radius:5px;text-align:center;display:flex;flex-direction:column;justify-content:center;cursor:pointer}@media only screen and (max-width: 600px){.selectRewards .rewardTile{flex:1 0 100px}.selectRewards .submit{width:100%}}

.setRewards .submit{margin:0 auto;display:block;font-size:18px;height:40px}.setRewards .rewards{display:flex;flex-direction:column}

.companyPending{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px}


.login{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:600px;margin:0 auto}.login .submit{margin-top:20px}.login .signUpLink{font-size:13px;margin-top:5px;font-style:italic}

.signUp{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:600px;margin:0 auto}.signUp .submit{margin-top:20px}.signUp .loginLink{font-size:13px;margin-top:5px;font-style:italic}

.setPassword{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:600px;margin:0 auto}.setPassword .submit{margin-top:20px}

.offerLetters{position:relative;margin-bottom:100px}.offerLetters .offerLetterTopSection{display:flex}.offerLetters .offerLetterTopSection .offerFilters{flex:1 1}.offerLetters .offerLetterTopSection .offerStats{flex:2 1;display:flex}.offerLetters .offerLetterTopSection .offerStatsTitle{font-weight:600}.offerLetters .offerLetterTopSection .offerStatsRow{display:flex}.offerLetters .offerLetterTopSection .offerStatsRow .offerStat{width:100px}.offerLetters .offerLetterTopSection .offerStatsLeft{flex:1 1;margin-left:15px}.offerLetters .offerLetterTopSection .offerStatsRight{flex:0 0 300px;height:250px;margin-top:25px}.offerLetters .offerActions{position:absolute;top:0;right:0}.offerLetters .offerActions a,.offerLetters .offerActions button{margin-right:10px}.offerLetters .offerActions a:last-child,.offerLetters .offerActions button:last-child{margin-right:0}.offerLetters .offerStatusFilters{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:300px}.offerLetters .offerNameFilter{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;width:300px;margin:15px 0;padding:15px 20px}.offerLetters .offerTable{max-height:1500px;overflow-y:auto;overflow-x:hidden}.offerLetters .tableIcon{cursor:pointer;color:#062677;font-size:20px;text-align:center;vertical-align:middle}.offerLetters .tableIcon.disabled{color:#bdbdbd}.offerLetters .navigateIcon{margin-top:-5px}.offerLetters .copyOfferLink{text-align:center}

.editApproversDialog .approverAddButton{margin-top:5px}.editApproversDialog .deleteButton{color:#f15b5b}

.createOfferLetter{width:400px}.createOfferLetter .createForm{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:30px}.createOfferLetter .addEmployeeRole{width:300px}.createOfferLetter .offerTextField{width:300px}.createOfferLetter .createOfferButtons{margin-top:20px}.createOfferLetter .createOfferButtons button{margin-right:10px}.createOfferLetter .spinnerSubtext{font-size:12px;font-style:italic}

.viewOfferLetter .offerPrimaryActions{position:absolute;right:15px;top:15px}.viewOfferLetter .offerPrimaryActions div{margin-right:10px}.viewOfferLetter .offerPrimaryActions div:last-child{margin-right:0}.viewOfferLetter .offerCard,.viewOfferLetter .offerApprovers{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;position:relative;padding:20px;width:500px;margin-top:30px;margin-bottom:15px}.viewOfferLetter .offerCard .cardLabel,.viewOfferLetter .offerApprovers .cardLabel{font-weight:500}.viewOfferLetter .offerCard .offerActions,.viewOfferLetter .offerApprovers .offerActions{position:absolute;right:10px;top:10px}.viewOfferLetter .offerApprovers{width:750px}.viewOfferLetter .approvalNote{margin-left:20px;font-style:italic}


.approveOfferLetter .employeeCard,.approveOfferLetter .approvalStatus{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;position:relative;padding:20px;width:500px;margin-top:30px;margin-bottom:15px}.approveOfferLetter .employeeCard .cardLabel,.approveOfferLetter .approvalStatus .cardLabel{font-weight:500}.approveOfferLetter .approvalActions{display:flex;margin-top:20px}.approveOfferLetter .approvalActions button{flex:1 1}.approveOfferLetter .approvalActions button:first-of-type{margin-right:25px}.approveOfferLetter .approvalActions button:last-of-type{margin-left:25px}.approveOfferLetter .approvalStatus{margin:10px auto}.approveOfferLetter .approvalNote{margin-top:10px}

.createBundle .employeeCard{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:500px;margin-top:30px;margin-bottom:30px}.createBundle .submitSuccess{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px}.createVerifyModal .verifyCheckbox{margin-top:10px}.previewModalText{padding:30px;font-size:18px;max-width:600px;text-align:left}.previewCopy{margin:0 auto;width:300px}.copyIcon{cursor:pointer;color:#062677;font-size:20px;text-align:center;vertical-align:middle}.bundlePreview{margin-top:30px}.bundlePreview .totalCompensation{font-size:18px;font-weight:600}.bundlePreview .fourDayEnabled{font-size:16px;font-weight:600}@media only screen and (max-width: 600px){.welcomeTour{position:unset;width:400px}.tourIntro{padding:10px;max-width:300px}}

.previewContents{display:flex}.previewContents div,.previewContents img{flex:1 1}.previewContents img{margin-left:50px;width:800px;-webkit-filter:drop-shadow(13px 10px 4px #707070);filter:drop-shadow(13px 10px 4px #707070)}@media only screen and (max-width: 600px){.previewContents{display:block}.previewContents img{width:100%;margin:20px 0}.previewContents button{width:100%}}

.setSalary .salarySpinner{width:500px;margin-bottom:15px}@media only screen and (max-width: 600px){.setSalary .salarySpinner{width:100%}.setSalary .nextButton{width:100%}}


