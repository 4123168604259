@import 'src/styles/variables';

.editReward {
  @include card-style;
  position: relative;
  padding: 20px 15px;
  margin-bottom: 20px;

  .rewardConfig {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 5px;
  }

  .rewardActions {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .deleteButton {
    color: $color-error;
  }

  .rewardLabel {
    font-size: 18px;
    font-weight: 600;
  }

  .rewardDropdown {
    flex: 0 1 100px;
    margin-right: 20px;
  }

  .rewardCheckboxes {
    flex: 1;
    align-self: self-end;
  }

  .rewardCheckbox {
    margin-bottom: 5px;
  }

  .rewardNumber {
    width: 125px;
    margin-right: 10px;
  }

  .rewardModifyLabel {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .rewardInfoIcon {
    margin-left: 5px;
  }

  .rewardSubText {
    font-size: 15px;
    font-style: italic;
  }

  .rewardEdit {
    font-size: 15px;
    margin-left: 10px;
    cursor: pointer;
  }

  .rewardEditText {
    width: 300px;
    display: inline-block;
  }
}

.modifyTooltipCopy {
  font-size: 13px;
  padding: 5px;
}
