@import 'src/styles/variables';

.setPassword {
  @include card-style;
  padding: 20px;
  width: 600px;
  margin: 0 auto;

  .submit {
    margin-top: 20px;
  }
}
