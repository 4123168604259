@import 'src/styles/variables';

.rewardCallout {
  padding: 20px 24px;
  width: 500px;
  z-index: 1501; // above tooltip

  .rewardCalloutTitle {
    margin-bottom: 5px;

    &:not(:first-of-type) {
      margin-top: 15px;
    }
  }
}

.rewardInfoIcon {
  color: $color-primary;
  font-size: 22px;
  user-select: none;
  cursor: pointer;
}
