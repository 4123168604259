@import 'src/styles/variables';

.notifications {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  z-index: 1501; // above tooltip

  div {
    padding: 15px 30px;
    color: $color-secondary;
    box-shadow: 2px 2px 2px $color-border;

    opacity: 1;
    animation-name: fadeInOutOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 8s; // match duration of notification
  }

  @keyframes fadeInOutOpacity {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .success {
    background: $color-success;
  }

  .error {
    background: $color-error;
  }

  .warning {
    background: $color-warning;
  }
}
