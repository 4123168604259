@import 'src/styles/variables';

.employeeList {
  position: relative;
  margin-bottom: 100px;
}

.employeeActions {
  position: absolute;
  top: 0;
  right: 0;

  button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.employeeStatusFilters {
  @include card-style;

  padding: 20px;
  width: 300px;
}

.employeeNameFilter {
  @include card-style;

  width: 300px;
  margin: 15px 0;
  padding: 15px 20px;
}

.employeeTable {
  max-height: 1000px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tableIcon {
  cursor: pointer;
  color: $color-primary;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
}

.navigateIcon {
  // minor hack to force alignment, not sure
  margin-top: -5px;
}