@import 'src/styles/variables';

.approveOfferLetter {
  .employeeCard, .approvalStatus {
    @include card-style;
    position: relative;
    padding: 20px;
    width: 500px;
    margin-top: 30px;
    margin-bottom: 15px;
  
    .cardLabel {
      font-weight: 500;
    }
  }

  .approvalActions {
    display: flex;
    margin-top: 20px;

    button {
      flex: 1;

      &:first-of-type {
        margin-right: 25px;
      }

      &:last-of-type {
        margin-left: 25px;
      }
    }
  }

  .approvalStatus {
    margin: 10px auto;
  }

  .approvalNote {
    margin-top: 10px;
  }
}

