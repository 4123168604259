$base-font: 'Open Sans', sans-serif;

$color-primary: #062677;
$color-secondary: #ffffff;
$color-body: #666666;
$color-bg: #f8f6f6;

$color-disabled: #bdbdbd;

$color-success: #67e85d;
$color-error: #f15b5b;
$color-warning: #ffe772;

$color-dashed: darken($color-bg, 30%);
$color-border: darken($color-bg, 10%);

@mixin card-style {
  background: $color-secondary;
  border: 1px solid $color-border;
  border-radius: 5px;
  box-shadow: 1px 1px 1px $color-border;
}
