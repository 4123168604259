.setSalary {
  .salarySpinner {
    width: 500px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .setSalary {
    .salarySpinner {
      width: 100%;
    }

    .nextButton {
      width: 100%;
    }
  }
}
