@import 'src/styles/variables';

.roleList {
  position: relative;
  margin-bottom: 100px;

  .roleStatusFilters {
    @include card-style;
  
    padding: 20px;
    width: 300px;
  }

  .roleActions {
    position: absolute;
    top: 0;
    right: 0;
  
    button {
      margin-right: 10px;
  
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .roleTable {
    max-height: 1500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .roleFilterGroup {
    display: flex;
  }
  
  .roleFilter {
    @include card-style;
  
    width: 300px;
    margin: 15px;
    padding: 15px 20px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .departmentFilter {
    display: flex;
    
    .departmentDropdown {
      flex: 1;
    }

    .departmentClear {
      flex: 0 0 25px;
      align-self: flex-end;
    }
  }
  
  .tableIcon {
    display: block;
    width: 40px;
    cursor: pointer;
    color: $color-primary;
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    margin: 0 auto;
  }

  .navigateIcon {
    margin: 0 auto;
    // minor hack to force alignment, not sure
    margin-top: -5px;
    font-size: 20px;
  }
}