@import 'src/styles/variables';

.reward {
  display: flex;
  border-top: 1px solid $color-bg;
  padding: 20px 15px;
  align-items: center;

  &:first-child {
    border-top: none;
  }

  .lock {
    width: 20px;
    height: 20px;
    user-select: none;
  }
}

.infoNameWrapper {
  display: flex;
  height: 22px;
  align-items: center;
  flex: 0 0 210px;
  margin-right: 10px;
}

.infoWrapper {
  cursor: default;
  margin-right: 15px;
  width: 22px;
  height: 22px;
}

.name {
  margin-right: 15px;
  flex: 0 0 200px;
}

.lock {
  margin-right: 3px;
  z-index: 1;

  .lockIcon {
    cursor: pointer;
    color: $color-primary;
    font-size: 20px;
  }

  &.disabled .lockIcon {
    color: $color-disabled;
  }
}

.sliderWrapper {
  display: flex;
  flex: 2;
}

.slider {
  flex: 1;
  height: 28px;
}

.defaultMarker {
  position: relative;
  width: 1px;
  height: 30px;
  z-index: -1;
}

.defaultMarker:after {
  content:"";
  position: absolute;
  z-index: -1;
  top: -80%;
  bottom: 0;
  left: 50%;
  height: 100%;
  height: inherit;
  border-left: 1px dashed $color-dashed;
}

.rewardTooltip {
  font-size: 12px !important;
  background-color: $color-primary !important;

  &.rewardDisabled {
    background-color: $color-disabled !important;
  }
}

.rewardTooltipArrow {
  color: $color-primary !important;

  &.rewardDisabled {
    color: $color-disabled !important;
  }
}