@import 'src/styles/variables';

.rewardTile {
  position: relative;
  width: 150px;
  text-align: center;
  background: $color-secondary;
  padding: 20px;
  margin: 15px;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  user-select: none;

  &.active {
    background: rgba(lighten($color-primary, 30%), 0.2);
  }
}

.rewardTileLabel {
  font-weight: 500;
}

.delete {
  position: absolute;
  top: 15px;
  right: 15px;
  fill: $color-body;
}
