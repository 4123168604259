@import 'src/styles/variables';

.signUp {
  @include card-style;
  padding: 20px;
  width: 600px;
  margin: 0 auto;

  .submit {
    margin-top: 20px;
  }

  .loginLink {
    font-size: 13px;
    margin-top: 5px;
    font-style: italic;
  }
}
