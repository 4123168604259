@import 'src/styles/variables';

.feedback {
  .employeeInfo {
    @include card-style;
    width: 500px;
    margin: 0 auto;
    padding: 15px;
  
    button {
      margin-top: 15px;
    }
  }
  
  .questions {
    @include card-style;
    padding: 15px;
  
    .rewards {
      display: flex;
      .feedbackRewardTile {
        flex: 1;
        background: $color-bg;
  
        &.active {
          background: rgba(lighten($color-primary, 30%), 0.2);
        }
      }
    }
  }

  .success {
    @include card-style;
    padding: 15px;
    width: 600px;
    margin: 0 auto;
    .successMessage {
      text-align: center;
      font-weight: bold;
    }

  }

  .feedbackSubtext {
    font-size: 14px;
    font-style: italic;
  }
}

