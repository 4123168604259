@import 'src/styles/variables';

.setRewards {
  .submit {
    margin: 0 auto;
    display: block;
    font-size: 18px;
    height: 40px;
  }

  .rewards {
    display: flex;
    flex-direction: column;
  }
}
