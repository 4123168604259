@import 'src/styles/variables';

.adminDashboard {
  margin: -30px;

  .adminDashboardContent {
    padding: 30px;
  }
}

.statCards {
  display: flex;
  margin: 50px 0;
}

.statTable {
  margin-top: 15px;
}

.statCard, .statTable {
  @include card-style;
  border: 1px solid $color-border;
  border-radius: 5px;
  box-shadow: 1px 1px 1px $color-border;
}

.statSort {
  display: flex;
  width: 50%;

  .statSortSelect {
    flex: 1;
  }

  .statSortOption {
    flex: 3;
    margin: 0 15px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.statCard {
  margin: 20px;
  padding: 20px;
  width: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .statTitle {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
  }

  .stat {
    flex: 3;
  }
}

.statTable {
  background: $color-secondary;
  padding: 20px;
  width: auto;

  .statTitle {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
  }
}