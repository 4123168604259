@import 'src/styles/variables';

.createOfferLetter {
  width: 400px;

  .createForm {
    @include card-style;
    padding: 30px;
  }

  .addEmployeeRole {
    width: 300px;
  }

  .offerTextField {
    width: 300px;
  }

  .createOfferButtons {
    margin-top: 20px;
    
    button {
      margin-right: 10px;
    }
  }

  .spinnerSubtext {
    font-size: 12px;
    font-style: italic;
  }
}
