@import 'src/styles/variables';

.createBundle {
  .employeeCard {
    @include card-style;
    padding: 20px;
    width: 500px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .submitSuccess {
    @include card-style;
    padding: 20px;
  }
}

.createVerifyModal {
  .verifyCheckbox {
    margin-top: 10px;
  }
}

.previewModalText {
  padding: 30px;
  font-size: 18px;
  max-width: 600px;
  text-align: left;
}

.previewCopy {
  margin: 0 auto;
  width: 300px;
}

.copyIcon {
  cursor: pointer;
  color: $color-primary;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
}

.bundlePreview {
  margin-top: 30px;

  .totalCompensation {
    font-size: 18px;
    font-weight: 600;
  }

  .fourDayEnabled {
    font-size: 16px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 600px) {
  .welcomeTour {
    position: unset;
    width: 400px;
  }

  .tourIntro {
    padding: 10px;
    max-width: 300px;
  }
}