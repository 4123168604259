@import 'src/styles/variables';

.requestDemo {
  display: block;
  margin: 0 auto;
}

.features {
  @include card-style;
  padding: 10px;

  div {
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    i {
      margin-right: 5px;
      color: $color-success;
    }
  }
}

.planComparison {
  margin-top: 30px;
  display: flex;

  .plan {
    flex: 1;

    &:first-of-type {
      padding-right: 15px;
    }

    &:last-of-type {
      padding-left: 15px;
    }
  }
}