@import 'src/styles/variables';

$nav-width: 250px;

html {
  height: 100%;
}

body {
  min-height: 100%;
  position: relative;
}

.header {
  background: $color-primary;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;

  img {
    height: 50px;
  }
}

.content {
  position: relative;
  padding: 30px;

  &.navPresent {
    margin-left: $nav-width;
  }
}

.navBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $color-secondary;
  width: $nav-width;
  position: absolute;
  top: 70px;
  left: 0;
  height: calc(100% - 70px);
}

.navBarFooter {
  font-size: 14px;
  color: $color-body;
  text-align: center;
  padding: 30px 10px;
}

h1, h2, h3, h4, h5 {
  margin-top: 0;
}

@media only screen and (max-width: 600px) {
  .content {
    padding: 5px;
  }
}