@import 'src/styles/variables';

.verifyCompany {
  @include card-style;
  padding: 20px;
  margin: 0 auto;
  width: 500px;

  .submitButton {
    margin-top: 15px;
  }
}
