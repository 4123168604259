.inviteTeam {
  .inviteLink {
    position: relative;
    width: 500px;
    margin: 0 auto;
  }
  
  .inviteCopy {
    cursor: pointer;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    position: absolute;
    bottom: 6px;
    right: 8px;
    top: auto;
    font-size: 16px;
    line-height: 18px;
  }
  
  .inviteCopied {
    font-style: italic;
    font-weight: 600;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: opacity 1s ease;
  
    &.fadeIn {
      opacity: 1;
    }
  }
  
  .statCards {
    display: flex;
    margin: 50px 0;
  }

  .question {
    margin-top: 20px;
  }

  .rewards {
    display: flex;
  }
}
