@import 'src/styles/variables';

.employeeCard {
  @include card-style;
  position: relative;
  padding: 20px;
  width: 500px;
  margin-top: 30px;
  margin-bottom: 15px;

  .emailWarning {
    display: inline-block;
    vertical-align: middle;

    i {
      color: darken($color-error, 20%);
    }

    i, span {
      vertical-align: middle;
    }

    span {
      font-style: italic;
    }
  }

  .cardLabel {
    font-weight: 500;
    vertical-align: middle;
  }

  .employeeActions {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.employeeBundle {
  margin-top: 30px;

  .totalCompensation {
    font-size: 18px;
    font-weight: 600;
  }

  .fourDayEnabled {
    font-size: 16px;
    font-weight: 600;
  }
}
