.previewContents {
  display: flex;

  div, img {
    flex: 1;
  }

  img {
    margin-left: 50px;
    width: 800px;
    filter: drop-shadow(13px 10px 4px #707070);
  }
}


@media only screen and (max-width: 600px) {
  .previewContents {
    display: block;

    img {
      width: 100%;
      margin: 20px 0;
    }

    button {
      width: 100%;
    }
  }
}