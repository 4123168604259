@import 'src/styles/variables';

.editApproversDialog {
  .approverAddButton {
    margin-top: 5px;
  }
  .deleteButton {
    color: $color-error;
  }  
}
