@import 'src/styles/variables';

.rewards {
  position: relative;
  .submit {
    margin: 0 auto;
    display: block;
    font-size: 18px;
    height: 40px;
  }

  .rewardItems {
    display: flex;
    flex-direction: column;
  }

  .rewardAddButton {
    position: absolute;
    right: 0;
    margin: 10px;
  }
}
