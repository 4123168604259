@import 'src/styles/variables';

.viewOfferLetter {
  .offerPrimaryActions {
    position: absolute;
    right: 15px;
    top: 15px;

    div {
      margin-right: 10px;
  
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .offerCard, .offerApprovers {
    @include card-style;
    position: relative;
    padding: 20px;
    width: 500px;
    margin-top: 30px;
    margin-bottom: 15px;
  
    .cardLabel {
      font-weight: 500;
    }
  
    .offerActions {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  
  .offerApprovers {
    width: 750px;
  }

  .approvalNote {
    margin-left: 20px;
    font-style: italic;
  }
}
