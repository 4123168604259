@import 'src/styles/variables';

.statCard {
  @include card-style;
  border: 1px solid $color-border;
  border-radius: 5px;
  box-shadow: 1px 1px 1px $color-border;
  margin: 20px;
  padding: 20px;
  width: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .statTitle {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
  }

  .stat {
    flex: 3;
  }
}
