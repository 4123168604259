@import 'src/styles/variables';

.selectRewards {
  .submit {
    margin: 0 auto;
    display: block;
    font-size: 18px;
    height: 40px;
  }
  .rewards {
    display: flex;
    flex-wrap: wrap;
  }
  
  .addCustom {
    background: $color-secondary;
    width: 150px;
    padding: 20px;
    margin: 15px;
    border: 1px dashed $color-primary;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }
  
}

@media only screen and (max-width: 600px) {
  .selectRewards {
    .rewardTile {
      flex: 1 0 100px;
    }

    .submit {
      width: 100%;
    }
  }
}