.roleModal {
  .pointSpinner {
    margin-top: 15px;
    width: 50%;
  }

  .fourDay {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      flex: 1;

      &:first-of-type {
        margin-right: 5px;
      }

      &:last-of-type {
        margin-left: 5px;
      }
    }
  }
}
