@import 'src/styles/variables';

.bundleBuilder {
  padding: 30px;
  position: relative;
  text-align: center;

  .totalCompensation {
    min-height: 48px;
  }

  .fourDay {
    @include card-style;
    padding: 20px;
    width: 400px;
    margin: 0 auto;
  
    .fourDayTitle {
      font-weight: bold;
    }

    .fourDayToggle {
      margin-top: 10px;
      margin-left: 150px;
    }
  }

  .disableMessage {
    @include card-style;
    padding: 20px 30px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .notes {
    max-width: 600px;
    margin: 0 auto 15px auto;
  }
}

.tourIntro {
  width: 500px;
  padding: 40px;

  .tourIntroSubmit {
    display: block;
    margin: 0 auto;
  }
}

.pointsRemaining {
  font-size: 32px;
  font-weight: 500;
  height: 48px;
  max-width: 600px;
  margin: 0 auto;
}

.resetButton {
  margin-left: 15px;
}

@media only screen and (max-width: 600px) {
  .pointsRemaining {
    height: auto;
  }

  .bundleBuilder {
    padding: 0;

    .fourDay {
      padding: 5px;
      width: 100%;
    }
  }
}